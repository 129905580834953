@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        margin: 0;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family:
        'Inter var',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
#root > div {
    min-height: 100vh;
}

.Select__input {
    box-shadow: none !important;
}

/* scroll-bar css... */
/* ::-webkit-scrollbar {
    width: 8px !important;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
} */
